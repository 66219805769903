import { default as indexuPGHwmSdyhMeta } from "/application/pages/chat/index.vue?macro=true";
import { default as indexDIDTThUGY4Meta } from "/application/pages/clients/index.vue?macro=true";
import { default as indexVgwZ4zzv9VMeta } from "/application/pages/community/index.vue?macro=true";
import { default as _91id_93g9cCsOzNXoMeta } from "/application/pages/deals/deal/[id].vue?macro=true";
import { default as indexMkaXb3DkFLMeta } from "/application/pages/deals/index.vue?macro=true";
import { default as indexUEHw9xstOJMeta } from "/application/pages/index.vue?macro=true";
import { default as indexJjnsjgN6J1Meta } from "/application/pages/investments/index.vue?macro=true";
import { default as loginFLbOmRceC3Meta } from "/application/pages/login.vue?macro=true";
import { default as indexfRTLMvVnE0Meta } from "/application/pages/pdf-preview/index.vue?macro=true";
import { default as _91id_930urpCUNNJkMeta } from "/application/pages/presentation/[id].vue?macro=true";
import { default as _91id_93g2TIdqNaidMeta } from "/application/pages/presentation/[slug]/object/[id].vue?macro=true";
import { default as indexkybNhr9IhsMeta } from "/application/pages/profile/index.vue?macro=true";
import { default as indexYndu4RTyApMeta } from "/application/pages/search-objects/[id]/index.vue?macro=true";
import { default as indexadyxNBYBuaMeta } from "/application/pages/search-objects/index.vue?macro=true";
import { default as indexrHhKpr00XVMeta } from "/application/pages/search-purchaser/index.vue?macro=true";
import { default as index2S2ATkoZDCMeta } from "/application/pages/training/index.vue?macro=true";
export default [
  {
    name: indexuPGHwmSdyhMeta?.name ?? "chat",
    path: indexuPGHwmSdyhMeta?.path ?? "/chat",
    meta: indexuPGHwmSdyhMeta || {},
    alias: indexuPGHwmSdyhMeta?.alias || [],
    redirect: indexuPGHwmSdyhMeta?.redirect || undefined,
    component: () => import("/application/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: indexDIDTThUGY4Meta?.name ?? "clients",
    path: indexDIDTThUGY4Meta?.path ?? "/clients",
    meta: indexDIDTThUGY4Meta || {},
    alias: indexDIDTThUGY4Meta?.alias || [],
    redirect: indexDIDTThUGY4Meta?.redirect || undefined,
    component: () => import("/application/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: indexVgwZ4zzv9VMeta?.name ?? "community",
    path: indexVgwZ4zzv9VMeta?.path ?? "/community",
    meta: indexVgwZ4zzv9VMeta || {},
    alias: indexVgwZ4zzv9VMeta?.alias || [],
    redirect: indexVgwZ4zzv9VMeta?.redirect || undefined,
    component: () => import("/application/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93g9cCsOzNXoMeta?.name ?? "deals-deal-id",
    path: _91id_93g9cCsOzNXoMeta?.path ?? "/deals/deal/:id()",
    meta: _91id_93g9cCsOzNXoMeta || {},
    alias: _91id_93g9cCsOzNXoMeta?.alias || [],
    redirect: _91id_93g9cCsOzNXoMeta?.redirect || undefined,
    component: () => import("/application/pages/deals/deal/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMkaXb3DkFLMeta?.name ?? "deals",
    path: indexMkaXb3DkFLMeta?.path ?? "/deals",
    meta: indexMkaXb3DkFLMeta || {},
    alias: indexMkaXb3DkFLMeta?.alias || [],
    redirect: indexMkaXb3DkFLMeta?.redirect || undefined,
    component: () => import("/application/pages/deals/index.vue").then(m => m.default || m)
  },
  {
    name: indexUEHw9xstOJMeta?.name ?? "index",
    path: indexUEHw9xstOJMeta?.path ?? "/",
    meta: indexUEHw9xstOJMeta || {},
    alias: indexUEHw9xstOJMeta?.alias || [],
    redirect: indexUEHw9xstOJMeta?.redirect || undefined,
    component: () => import("/application/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJjnsjgN6J1Meta?.name ?? "investments",
    path: indexJjnsjgN6J1Meta?.path ?? "/investments",
    meta: indexJjnsjgN6J1Meta || {},
    alias: indexJjnsjgN6J1Meta?.alias || [],
    redirect: indexJjnsjgN6J1Meta?.redirect || undefined,
    component: () => import("/application/pages/investments/index.vue").then(m => m.default || m)
  },
  {
    name: loginFLbOmRceC3Meta?.name ?? "login",
    path: loginFLbOmRceC3Meta?.path ?? "/login",
    meta: loginFLbOmRceC3Meta || {},
    alias: loginFLbOmRceC3Meta?.alias || [],
    redirect: loginFLbOmRceC3Meta?.redirect || undefined,
    component: () => import("/application/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexfRTLMvVnE0Meta?.name ?? "pdf-preview",
    path: indexfRTLMvVnE0Meta?.path ?? "/pdf-preview",
    meta: indexfRTLMvVnE0Meta || {},
    alias: indexfRTLMvVnE0Meta?.alias || [],
    redirect: indexfRTLMvVnE0Meta?.redirect || undefined,
    component: () => import("/application/pages/pdf-preview/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930urpCUNNJkMeta?.name ?? "presentation-id",
    path: _91id_930urpCUNNJkMeta?.path ?? "/presentation/:id()",
    meta: _91id_930urpCUNNJkMeta || {},
    alias: _91id_930urpCUNNJkMeta?.alias || [],
    redirect: _91id_930urpCUNNJkMeta?.redirect || undefined,
    component: () => import("/application/pages/presentation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93g2TIdqNaidMeta?.name ?? "presentation-slug-object-id",
    path: _91id_93g2TIdqNaidMeta?.path ?? "/presentation/:slug()/object/:id()",
    meta: _91id_93g2TIdqNaidMeta || {},
    alias: _91id_93g2TIdqNaidMeta?.alias || [],
    redirect: _91id_93g2TIdqNaidMeta?.redirect || undefined,
    component: () => import("/application/pages/presentation/[slug]/object/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkybNhr9IhsMeta?.name ?? "profile",
    path: indexkybNhr9IhsMeta?.path ?? "/profile",
    meta: indexkybNhr9IhsMeta || {},
    alias: indexkybNhr9IhsMeta?.alias || [],
    redirect: indexkybNhr9IhsMeta?.redirect || undefined,
    component: () => import("/application/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexYndu4RTyApMeta?.name ?? "search-objects-id",
    path: indexYndu4RTyApMeta?.path ?? "/search-objects/:id()",
    meta: indexYndu4RTyApMeta || {},
    alias: indexYndu4RTyApMeta?.alias || [],
    redirect: indexYndu4RTyApMeta?.redirect || undefined,
    component: () => import("/application/pages/search-objects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexadyxNBYBuaMeta?.name ?? "search-objects",
    path: indexadyxNBYBuaMeta?.path ?? "/search-objects",
    meta: indexadyxNBYBuaMeta || {},
    alias: indexadyxNBYBuaMeta?.alias || [],
    redirect: indexadyxNBYBuaMeta?.redirect || undefined,
    component: () => import("/application/pages/search-objects/index.vue").then(m => m.default || m)
  },
  {
    name: indexrHhKpr00XVMeta?.name ?? "search-purchaser",
    path: indexrHhKpr00XVMeta?.path ?? "/search-purchaser",
    meta: indexrHhKpr00XVMeta || {},
    alias: indexrHhKpr00XVMeta?.alias || [],
    redirect: indexrHhKpr00XVMeta?.redirect || undefined,
    component: () => import("/application/pages/search-purchaser/index.vue").then(m => m.default || m)
  },
  {
    name: index2S2ATkoZDCMeta?.name ?? "training",
    path: index2S2ATkoZDCMeta?.path ?? "/training",
    meta: index2S2ATkoZDCMeta || {},
    alias: index2S2ATkoZDCMeta?.alias || [],
    redirect: index2S2ATkoZDCMeta?.redirect || undefined,
    component: () => import("/application/pages/training/index.vue").then(m => m.default || m)
  }
]