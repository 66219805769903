import { Notify, QNotifyCreateOptions } from 'quasar'
import { ToastStore } from '~/store/toastStore'
import { apiGet } from '~/services/api'
import serafimaAvatar from '~/assets/img/AI/serafima.png'

let localToastID: string = ''

Notify.registerType('achievement', {
  color: '#2C2933',
  position: 'top-right',
  timeout: 4000,
  avatar: serafimaAvatar,
  html: true,
  classes: 'toast-achievement',
  actions: [
    {
      icon: 'close',
      size: 'small',
      color: 'white',
      round: true,
      handler: () => {
        const ToastStoreControl = ToastStore()
        ToastStoreControl.makeRead(localToastID)
      },
    },
  ],
})
Notify.registerType('system', {
  color: '#2C2933',
  position: 'top-right',
  timeout: 3000,
  html: true,
  actions: [
    {
      icon: 'close',
      size: 'small',
      color: 'black',
      round: true,
      handler: () => {
        const ToastStoreControl = ToastStore()
        ToastStoreControl.makeRead(localToastID)
      },
    },
  ],
})

Notify.registerType('system_link', {
  color: '#2C2933',
  position: 'top-right',
  timeout: 3000,
  html: true,
  actions: [
    {
      icon: 'close',
      size: 'small',
      color: 'black',
      round: true,
      handler: () => {
        const ToastStoreControl = ToastStore()
        ToastStoreControl.makeRead(localToastID)
      },
    },
  ],
})
Notify.registerType('server_error', {
  color: '#2C2933',
  position: 'top-right',
  timeout: 3000,
  html: true,
  actions: [
    {
      icon: 'close',
      size: 'small',
      color: 'black',
      round: true,
      handler: () => {},
    },
  ],
})

class createToast {
  notifData: undefined | QNotifyCreateOptions
  clickHandler: any
  constructor(args: {
    type: string
    title?: string
    description?: string
    id?: string
    condition?: string
  }) {
    const ToastStoreControl = ToastStore()
    localToastID = args.id ? args.id : ''
    const clickAble = 'Посмотреть.'
    let captionContent = ''
    if (args.type === 'achievement') {
      captionContent = `${args.description}<span class="toast-interactive-text">   ${clickAble}</span><div class="handleClick-area"></div>`
      this.notifData = {
        type: args.type,
        caption: captionContent,
        message: 'Серафима',
      }
    } else {
      captionContent = `${args.description}<div class="handleClick-area"></div>`
      this.notifData = {
        type: args.type,
        caption: captionContent,
        message: args.title,
        classes: `${'toast-system ' + args.condition}`,
      }
    }

    this.clickHandler = (event: any) => {
      const target = event.target as HTMLElement
      const dismiss: HTMLButtonElement | null = document.querySelector('.q-btn-item')
      if (target.classList.contains('toast-interactive-text')) {
        dismiss?.click()
        ToastStoreControl.makeRead(args.id ? args.id : '')
        ToastStoreControl.$state.ShowlistNotifications?.show()
        document.removeEventListener('click', this.clickHandler)
      } else if (target.classList.contains('handleClick-area')) {
        dismiss?.click()
        ToastStoreControl.makeRead(args.id ? args.id : '')
        ToastStoreControl.$state.ShowlistNotifications?.show()
        document.removeEventListener('click', this.clickHandler)
      } else {
        dismiss?.click()
        document.removeEventListener('click', this.clickHandler)
      }
    }
  }

  createNewToast(caption?: string) {
    const ToastStoreControl = ToastStore()
    ToastStoreControl.$state.isBadge = true
    document.addEventListener('click', this.clickHandler)
    setTimeout(() => {
      document.removeEventListener('click', this.clickHandler)
    }, 3000)
    console.log('this.notifData: ', this.notifData)

    return Notify.create({ caption: caption, ...this.notifData })
  }
}

const fetchNotifications = async () => {
  const ToastStoreControl = ToastStore()

  try {
    const { data } = await apiGet({ url: 'api/notification/flash' })

    if (data) {
      ToastStoreControl.$state.createToast = false
      ;(data as any).value.data.forEach((toast: any) => {
        const newToast = new createToast({
          type: toast.type,
          title: toast.title,
          description: toast.description,
          id: toast.id,
          condition: 'toast-complete',
        })
        newToast.createNewToast()
        ToastStoreControl.fetchListNotification()
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export { createToast, fetchNotifications }
