import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAiPerson, LazyDownUpPopUp, LazyMobilStagePicker, LazyThermometerPopUp, LazyAgentContactsPopUp, LazyAgentMobilInfo, LazyAgentStickyHeader, LazyNoActionMapPopUp, LazyAgentCart, LazyAgentContacts, LazyBaseCard, LazyBaseIcon, LazyCookieNotification, LazyBaseHeader, LazyUserMenu, LazyCustomLoader, LazyNoActionMap, LazyStyledMap, LazyPhotoGalleryBase, LazySideBar, LazyBaseSuggestions, LazyThePage, LazyAddInOutCome, LazyFixBadget, LazyDeleteAllBudget, LazyFin, LazyHeadEnvelop, LazyPageHeader, LazyFiltersPopUp, LazyPopUp, LazyAvardsPage, LazyAvardsPopUp, LazyAvatarMenu, LazyProfileContact, LazyCropperComponent, LazyProfileNotifications, LazyProfilePrivacy, LazyProfileContainer, LazyProfileRates, LazyProfileVizitka, LazyDeleteAccount, LazyProfileAbout, LazyProfileWorkAs, LazySearchSellPopUp, LazyLIstObjectsView, LazySearchSuggestions, LazyAddToList, LazyShortInfoBar, LazyObjectStandalone, LazyYMapObjects, LazyAfterRegistration, LazyEndDeal, LazyAgentInfo, LazyDealBlock, LazyPhotoPresentationEdit, LazyPresentationEditPopUp, LazyHistoryDisplay, LazyPdfComponent, LazyPdfPopUp, LazyStandalonePdf, LazyAllObjs, LazyPresentationStandalone, LazyPresentationContainer, LazyRecordWatchButt, LazyLeaveReview, LazyPresentationTable, LazySimilarObjects, LazyStatusSelect, LazyCreateDeal, LazyDealAbout, LazyDealCondition, LazyDealDescription, LazyDealDocs, LazyDealHead, LazyDealLines, LazyDealPhotos, LazyListItemButtsSelect, LazyListItemInput, LazyListItemM3input, LazyListItemMulty, LazyListItemSelect, LazySuggestionsDadata, LazyThermometer, LazyYMap, LazySuggestions, LazyCanbanBody, LazyHeadPanel, LazyViewDealsSelect, LazyCustomTable, LazyInputCode, LazyNotifDisplay, LazyToast, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["AiPerson", LazyAiPerson],
["DownUpPopUp", LazyDownUpPopUp],
["MobilStagePicker", LazyMobilStagePicker],
["ThermometerPopUp", LazyThermometerPopUp],
["AgentContactsPopUp", LazyAgentContactsPopUp],
["AgentMobilInfo", LazyAgentMobilInfo],
["AgentStickyHeader", LazyAgentStickyHeader],
["NoActionMapPopUp", LazyNoActionMapPopUp],
["AgentCart", LazyAgentCart],
["AgentContacts", LazyAgentContacts],
["BaseCard", LazyBaseCard],
["BaseIcon", LazyBaseIcon],
["CookieNotification", LazyCookieNotification],
["BaseHeader", LazyBaseHeader],
["UserMenu", LazyUserMenu],
["CustomLoader", LazyCustomLoader],
["NoActionMap", LazyNoActionMap],
["StyledMap", LazyStyledMap],
["PhotoGalleryBase", LazyPhotoGalleryBase],
["SideBar", LazySideBar],
["BaseSuggestions", LazyBaseSuggestions],
["ThePage", LazyThePage],
["AddInOutCome", LazyAddInOutCome],
["FixBadget", LazyFixBadget],
["DeleteAllBudget", LazyDeleteAllBudget],
["Fin", LazyFin],
["HeadEnvelop", LazyHeadEnvelop],
["PageHeader", LazyPageHeader],
["FiltersPopUp", LazyFiltersPopUp],
["PopUp", LazyPopUp],
["AvardsPage", LazyAvardsPage],
["AvardsPopUp", LazyAvardsPopUp],
["AvatarMenu", LazyAvatarMenu],
["ProfileContact", LazyProfileContact],
["CropperComponent", LazyCropperComponent],
["ProfileNotifications", LazyProfileNotifications],
["ProfilePrivacy", LazyProfilePrivacy],
["ProfileContainer", LazyProfileContainer],
["ProfileRates", LazyProfileRates],
["ProfileVizitka", LazyProfileVizitka],
["DeleteAccount", LazyDeleteAccount],
["ProfileAbout", LazyProfileAbout],
["ProfileWorkAs", LazyProfileWorkAs],
["SearchSellPopUp", LazySearchSellPopUp],
["LIstObjectsView", LazyLIstObjectsView],
["SearchSuggestions", LazySearchSuggestions],
["AddToList", LazyAddToList],
["ShortInfoBar", LazyShortInfoBar],
["ObjectStandalone", LazyObjectStandalone],
["YMapObjects", LazyYMapObjects],
["AfterRegistration", LazyAfterRegistration],
["EndDeal", LazyEndDeal],
["AgentInfo", LazyAgentInfo],
["DealBlock", LazyDealBlock],
["PhotoPresentationEdit", LazyPhotoPresentationEdit],
["PresentationEditPopUp", LazyPresentationEditPopUp],
["HistoryDisplay", LazyHistoryDisplay],
["PdfComponent", LazyPdfComponent],
["PdfPopUp", LazyPdfPopUp],
["StandalonePdf", LazyStandalonePdf],
["AllObjs", LazyAllObjs],
["PresentationStandalone", LazyPresentationStandalone],
["PresentationContainer", LazyPresentationContainer],
["RecordWatchButt", LazyRecordWatchButt],
["LeaveReview", LazyLeaveReview],
["PresentationTable", LazyPresentationTable],
["SimilarObjects", LazySimilarObjects],
["StatusSelect", LazyStatusSelect],
["CreateDeal", LazyCreateDeal],
["DealAbout", LazyDealAbout],
["DealCondition", LazyDealCondition],
["DealDescription", LazyDealDescription],
["DealDocs", LazyDealDocs],
["DealHead", LazyDealHead],
["DealLines", LazyDealLines],
["DealPhotos", LazyDealPhotos],
["ListItemButtsSelect", LazyListItemButtsSelect],
["ListItemInput", LazyListItemInput],
["ListItemM3input", LazyListItemM3input],
["ListItemMulty", LazyListItemMulty],
["ListItemSelect", LazyListItemSelect],
["SuggestionsDadata", LazySuggestionsDadata],
["Thermometer", LazyThermometer],
["YMap", LazyYMap],
["Suggestions", LazySuggestions],
["CanbanBody", LazyCanbanBody],
["HeadPanel", LazyHeadPanel],
["ViewDealsSelect", LazyViewDealsSelect],
["CustomTable", LazyCustomTable],
["InputCode", LazyInputCode],
["NotifDisplay", LazyNotifDisplay],
["Toast", LazyToast],
["NuxtIcon", LazyNuxtIcon]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
