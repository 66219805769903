import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin({
  name: 'sentry',
  enforce: 'post',
  async setup(nuxtApp) {
    const currentHost = window.location.hostname //для разработки!!!!!
    if (currentHost !== 'localhost') {
      const vueRouter = nuxtApp.$router as Router
      const config = useRuntimeConfig()
      Sentry.init({
        app: nuxtApp.vueApp,
        autoSessionTracking: true,
        dsn: config.public.env.SENTRY_API_KEY,
        environment: currentHost === 'https://agentum.pro' ? 'production' : 'development',
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: ['https://dev.agentum.pro', 'https://agentum.pro'],
            routingInstrumentation: Sentry.vueRouterInstrumentation(vueRouter, {
              routeLabel: 'path',
            }),
          }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        tracesSampleRate: 1.0,
        beforeSend(event, hint) {
          if (event.exception) {
            console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, {
              event,
              hint,
            })
          }
          return event
        },
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      })
    }
  },

  env: {
    islands: true,
  },
})
